.header{
    height: 100px;
    width: 100%;
    background-color: rgb(218, 218, 218);
    -webkit-box-shadow: 0px 10px 30px -7px rgba(0,0,0,0.46);
    -moz-box-shadow: 0px 10px 30px -7px rgba(0,0,0,0.46);
    box-shadow: 0px 10px 30px -7px rgba(0,0,0,0.46);
    padding: 10px;
    margin: 0;
    display: flex;
}

.title{
    position: relative;
    margin-left: 10px;
}

.containerLinks{
    position: absolute;
    right: 10px;
    display: flex;
}

.btnBlog{
    background-color: black;
    

    margin-left: 10px;
    height:5vh;
    border-radius: 5px 5px;
    width: 100px;
    color: white;
    -webkit-box-shadow: 0px 10px 30px -7px rgba(0,0,0,0.46);
    -moz-box-shadow: 0px 10px 30px -7px rgba(0,0,0,0.46);
    box-shadow: 0px 10px 30px -7px rgba(0,0,0,0.46);
    text-align: center;
    margin-top: 20px;
}

.btnLinkedIn{
    background-color: rgb(65, 81, 223);
    text-decoration: none;
    color: white;

    margin-left: 10px;
    height:5vh;
    border-radius: 5px 5px;
    width: 100px;
    color: white;
    -webkit-box-shadow: 0px 10px 30px -7px rgba(0,0,0,0.46);
    -moz-box-shadow: 0px 10px 30px -7px rgba(0,0,0,0.46);
    box-shadow: 0px 10px 30px -7px rgba(0,0,0,0.46);
    text-align: center;
    margin-top: 20px;
}